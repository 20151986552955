import { createGlobalStyle } from 'styled-components'
import expand3 from './../images/icons/expand3.svg'

export const GlobalStyle = createGlobalStyle`
  /* http://meyerweb.com/eric/tools/css/reset/ 
    v2.0 | 20110126
    License: none (public domain)
  */

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed, 
  figure, figcaption, footer, header, hgroup, 
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, 
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  *,
  *::before,
  *::after {
    box-sizing: border-box;
  }

  html {
    font-size: 10px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  p,
  a,
  ul,
  li,
  span,
  button,
  select,
  input,
  textarea,
  label, 
  form,
  div,
  svg {
    font-size: 1.6rem;
    line-height: 1.5;
    font-family: 'Source Sans Pro', sans-serif;
    margin: 0;
    padding: 0;
  }

  html,
  body,
  margin {
    margin: 0;
    padding: 0;
  }

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  button,
  a {
    outline: 0;
    border: 0;
    background: 0;
    padding: 0;
    cursor: pointer;
  }

  form {
    select,
    input {
      padding: 12px;
      border: 0;
      background-color: #F6F7F9;
      width: 100%;
      border-radius: 4px;
      font-size: 1.6rem;
      line-height: 2rem;
      color: #5C6370;
    }

    fieldset {
      display: flex;
      flex-direction: column;
      padding: 0;
    }

    select {
      background-image: url(${expand3});
      background-repeat: no-repeat;
      background-position: 95% 50%;
      appearance: none;
    }

    label {
      font-size: 1rem;
      line-height: 1.6rem;
      letter-spacing: 0.08rem;
      font-weight: 700;
      text-transform: uppercase;
      color: #94A3B8;
      margin-top: 20px;
      margin-bottom: 5px;
      display: inline-block;
      width: 100%;
    }

    input[type='submit'] {
      border: 0;
      background-color: #3C3A60;
      margin-top: 30px;
      color: #fff;
      font-weight: 700;
      margin-bottom: 40px;
      cursor: pointer;
    }
  }
`