import React from 'react'

import { StyledInput } from './styled'

export const Input = ({ type = 'text', id, placeholder, name, onChange, onClick, value, error, reference, inputPlaceholder }) => {
  console.log(error);

  return (
    <StyledInput 
      type={type} 
      id={id} 
      name={name} 
      placeholder={placeholder} 
      onChange={onChange} 
      onClick={onClick}
      value={value}
      error={error}
      ref={reference}
      placeholder={error ? `${name} is required` : inputPlaceholder}
    />
  )
}
