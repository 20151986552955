import React from 'react'
import { Animate } from 'react-simple-animate';

export const SlideInAnimation = ({ children, delay = 0.25 }) => {
  return (
    <Animate
      play={true}
      start={{
        transform: "translateY(70px)",
        opacity: "0"
      }}
      end={{
        transform: "translateY(0)",
        opacity: "1",
      }}
      duration={0.5}
      easeType={'ease-out'}
      delay={delay}
    >
      { children }
    </Animate>
  )
}
