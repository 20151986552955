import React from 'react'
import { GlobalStyle } from './styles'
import { Normalize } from 'styled-normalize'

export const Head = () => {
  return (
    <>
      <Normalize />
      <GlobalStyle />
    </>
  )
}
