import styled from 'styled-components'
import { Link } from 'gatsby'
import { LogoTypo } from './../../global/typography'

export const StyledSygnet = styled.div`
  padding-right: 15px;
  height: 40px;
  transition: ${({ theme }) => theme.transitions.fast };
`

export const StyledLogo = styled(Link)`
  ${LogoTypo}
  padding: 20px;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkprimary };
  text-decoration: none;

  @media ${({ theme }) => theme.bp.md} {
    margin: ${({ isDashboard }) => isDashboard ? '0' : '60px 0 0 60px' };
    padding: ${({ isDashboard }) => isDashboard ? '40px 50px 0 50px' : '0' };
  }

  &:hover {
    ${StyledSygnet} {
      transform: scale(0.9)
    }
  }
`