import React from 'react'
import sygnetIcon from './../../images/unibank-sygnet.svg'

import {
  StyledLogo,
  StyledSygnet
} from './styled'

export const SiteLogo = ({ path = "/dashboard", isDashboard }) => {
  return (
    <StyledLogo isDashboard={isDashboard} to={path}>
      <StyledSygnet>
        <img src={sygnetIcon} alt="Unibank" />
      </StyledSygnet>
      UniBank
    </StyledLogo>
  )
}
