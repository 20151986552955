export const theme = {
  colors: {
    primary: '#4B53B3',
    mutedprimary: '#46476D',
    darkprimary: '#1A202C',
    white: '#fff',
    black: '#000',
    black2: '#1C1E22',
    black3: '#1E293B',
    gray: '#9FB1CB',
    gray2: '#64748B',
    gray3: '#1B1D21',
    gray4: '#B4C4D9',
    gray5: '#6B7280',
    gray6: '#F9FAFB',
    gray7: '#F8FAFC',
    bluegray: '#708CB2',
    darkgray: '#39475B',
    silvergray: '#94A3B8',
    silver: '#5C6370',
    lightcreme: '#F6F7F9',
    red: '#D02F0D',
    placeholder: '#A4A5A6',
    placeholder2: '#94A3B8',
    modalOverlay: 'rgba(61, 61, 61, .85)',
    darkBorder: '#3C3A60',
    asideBorder: '#EDEDED',
    boxShadow: '0px 17px 318px rgba(33, 33, 33, 0.04), 0px 7.64243px 71.0295px rgba(33, 33, 33, 0.0238443), 0px 4.65983px 21.1473px rgba(33, 33, 33, 0.0161557)'
  },
  
  bp: {
    xs: 'all and (min-width: 480px)',
    sm: 'all and (min-width: 768px)',
    md: 'all and (min-width: 1080px)',
    xmd: 'all and (min-width: 1200px)',
    lg: 'all and (min-width: 1440px)'
  },

  transitions: {
    slow: '.4s ease-in',
    medium: '.3s ease-in',
    fast: '.2s ease-in'
  },

  radius: {
    small: '4px',
    medium: '8px',
    slarge: '14px',
    large: '16px',
    xlarge: '20px',
    round: '50%'
  }
}