import styled from 'styled-components'
import { InputField } from './../../global/typography'

export const StyledInput = styled.input`
  ${InputField};
  background-color: ${({ theme }) => theme.colors.lightcreme };
  padding: 12px 37px 12px 12px;
  color: ${({ theme }) => theme.colors.silver };
  border-radius: ${({ theme }) => theme.radius.small };
  box-shadow: ${({ error }) => error ? 'rgb(175 17 35) 0px 0px 0px 2px' : ''};

  &::placeholder {
    color: ${({ error, theme }) => error ? 'rgb(175 17 35)' : theme.colors.silver };
  }
`