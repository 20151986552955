export const LogoTypo = `
  font-size: 2.2rem;
  line-height: 2.6rem;
  font-weight: 700;
`;

export const Heading1 = `
  font-size: 3.6rem;
  line-height: 4.5rem;
  font-weight: 700;
`

export const Heading2 = `
  font-size: 2.6rem;
  line-height: 3.2rem;
  font-weight: 700;
`

export const Heading3 = `
  font-size: 2.4rem;
  line-height: 2.6rem;
  font-weight: 600;
`

export const Heading4 = `
  font-size: 2.9rem;
  line-height: 3.6rem;
  font-weight: 700;
`

export const Heading5 = `
  font-size: 2.1rem;
  line-height: 2.6rem;
  font-weight: 600;
`

export const Heading6 = `
  font-size: 3.2rem;
  line-height: 2.6rem;
  font-weight: 600;
`

export const Paragraph1 = `
  font-size: 1.6rem;
  line-height: 2.8rem;
  font-weight: 400;
`

export const Paragraph2 = `
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 600;
`

export const Paragraph3 = `
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-weight: 600;
`

export const Paragraph4 = `
  font-size: 1.6rem;
  line-height: 1.6rem;
  font-weight: 600;
`

export const Paragraph5 = `
  font-weight: 700;
  text-transform: uppercase;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.08rem;
`

export const Paragraph6 = `
  font-size: 1.4rem;
  line-height: 2.2rem;
`

export const InputField = `
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 400;
`

export const ButtonTypo = `
  font-size: 1.6rem;
  line-height: 2.2rem;
  letter-spacing: 0.04rem;
  font-weight: 600;
  text-decoration: none;
`

export const LabelTypo = `
  font-size: 1rem;
  line-height: 1.6rem;
  letter-spacing: 0.08rem;
  font-weight: 700;
  text-transform: uppercase;
`